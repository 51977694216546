import styled from 'styled-components';

export const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  width: 100%;
  justify-content: center;
  order: 0;
  @media ${({ theme }) => theme.device.mobile} {
    order: 1;
  }
`;

export const StyledUl = styled.ul`
  list-style-type: none; /* Remove default bullets */

  & li {
    position: relative;
    line-height: 1.25rem;
    font-size: 16px;
    margin-bottom: 2.5rem;
    font-family: 'Montserrat';
    margin-left: -1rem;
  }

  & li:last-child {
    margin-bottom: 0;
  }

  & li::before {
    content: '•'; /* Bullet character */
    color: #32b2fc; /* Bullet color */
    font-size: 2.75rem; /* Bullet size */
    max-width: 10px; /* Width to create space between bullet and text */
    position: absolute; /* Position it absolutely */
    left: -1.5rem; /* Align it to the left of the text */
    top: 0; /* Align to the top of the li element */
  }

  @media ${({ theme }) => theme.device.mobile} {
    & li {
      margin-bottom: 1.2rem;
    }
  }
`;
