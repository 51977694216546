// import { IconColor } from 'src/components/atoms/Icon';
import useLanguage from 'src/context/Language/useLanguage';

import { Instructions, StyledUl } from './styles';

export const WizardUploadInstructions = ({
  documentsAmount
}: {
  documentsAmount?: number | null;
}) => {
  const { translate: t } = useLanguage();

  const paystubsAmount = documentsAmount?.toString() || '';

  return (
    <Instructions>
      <StyledUl>
        <li
          dangerouslySetInnerHTML={{
            __html: t(
              'dup_wizard_first_instruction',
              paystubsAmount,
              t('dup_wizard_instruction_paystubs')
            )
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t('dup_wizard_second_instruction')
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t('dup_wizard_third_instruction')
          }}
        />
      </StyledUl>
    </Instructions>
  );
};
