import Button from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import Text from 'src/components/atoms/Text';
import styled from 'styled-components';

export const CenteredColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}rem` : 'unset')};
`;

export const StyledTrigger = styled.span`
  cursor: pointer;
`;

export const StyledText = styled(Text)<{ bold?: boolean; active?: boolean }>`
  font-family: 'Montserrat';
  font-size: 16px;
  line-height: 20px;
  color: ${({ active }) => (active ? '#32B2FC' : '#012c57')};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  white-space: nowrap;
`;

export const UploadListSection = styled.div`
  max-width: 392px;
  border-radius: 10px;
  border: 1px solid #012c57;
  padding: 2rem;
  display: flex;
  gap: 1rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.2rem;
  }

  .inputFile {
    display: none;
  }
`;

export const FileNameRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

export const UploadsList = styled(CenteredColumn)`
  gap: 0.5rem;
  min-height: 90px;
  @media ${({ theme }) => theme.device.mobile} {
    min-height: 70px;
  }
`;

export const RemoveIcon = styled(Icon)<{ active?: boolean }>`
  & svg path {
    stroke: ${({ active }) => (active ? '#32B2FC' : '#012c57')};
  }
`;

export const ReplaceButton = styled(Button)`
  background: #32b2fc;
  border-radius: 10px;
  border: 1px solid #32b2fc;
  font-family: 'Montserrat';
  line-height: 20px;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 1rem 2.5rem;
  font-size: 16px;
`;
