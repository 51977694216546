import styled from 'styled-components';

export const Container = styled.main`
  background: #fff;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  gap: 1rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0.5rem;
  }
`;
