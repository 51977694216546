import { envVars } from 'src/constants/config';

export interface IConstants {
  env: string;
  data_dog: { url: string; token: string };
  server_url: string;
  document_portal: string;
  frontend_url: string;
  api_url: string;
  epa_url: string;
  feature_flag_api_url: string;
  bucket_url?: string; // Deprecated
  metabase: {
    url: string;
    dashboards?: {
      [name: string]: {
        id: number;
        report_type: string;
      };
    };
  };
  adobe: {
    pdf_token: string;
  };
  heap: {
    app_id: string;
  };
}

/**
 * Overrides configurations from the Old Envs for the New Ens.
 * @param constants The constants set to override URLs in.
 * @param host The hostname of the server.
 * @returns Constants with URLs overridden.
 */
const overrideUrls = (constants: IConstants, host: string): IConstants => {
  // Drop the first subdomain, if present, so it works for interpolation
  const hostParts = host.split('.');
  let hostBase = hostParts.slice(hostParts.length > 2 ? 1 : 0).join('.');
  let docPortalHost = 'documentportal.info';
  if (/\.dev\./.test(host)) docPortalHost = `dev.${docPortalHost}`;
  if (/\.test\./.test(host)) docPortalHost = `test.${docPortalHost}`;
  if (/\.uat\./.test(host)) docPortalHost = `uat.${docPortalHost}`;
  if (/\.demo\./.test(host)) docPortalHost = `demo.${docPortalHost}`;

  // If this host _is_ a docportal address, we've gotta replace hostBase with
  // the snappt.com equivalent (e.g. dev.documentportal.info > dev.snappt.com)
  if (docPortalHost === hostBase) {
    hostBase = host.replace('documentportal.info', 'snappt.com');
  }

  const urls = {
    document_portal: `https://${docPortalHost}`,
    server_url: `https://fraud-api.${hostBase}`,
    frontend_url: `https://fraud.${hostBase}`,
    api_url: `https://fraud-api.${hostBase}/v2`,
    epa_url: `https://enterprise-api.${hostBase}`,
    feature_flag_api_url: `https://feature-flags.${hostBase}`
  };

  // Clone and override
  const clone = structuredClone(constants);
  delete clone.bucket_url; // Not used, just delete it.
  return Object.assign({}, clone, urls);
};

const configVars = (): IConstants => {
  const service = window.location.hostname;

  if (service.includes('localhost') || service.includes('127.0.0.1')) {
    return envVars.development;
  }
  if (service.includes('-sandbox') || service.includes('sandbox.')) {
    return envVars.sandbox;
  }
  if (service.includes('-ralph') || service.includes('ralph.')) {
    return envVars.ralph;
  }
  if (service.includes('-staging') || service.includes('staging.')) {
    return envVars.staging;
  }
  if (service.includes('-ep-uat') || service.includes('ep-uat.')) {
    return envVars.epuat;
  }

  // New env support
  if (/dev\.(snappt|wintheknifefight)\.com/.test(service)) {
    return overrideUrls(envVars.ralph, service);
  }
  if (/test\.(snappt|wintheknifefight)\.com/.test(service)) {
    return overrideUrls(envVars.sandbox, service);
  }
  if (/uat\.(snappt|wintheknifefight)\.com/.test(service)) {
    return overrideUrls(envVars.sandbox, service);
  }
  if (/demo\.(snappt|wintheknifefight)\.com/.test(service)) {
    return overrideUrls(envVars.epuat, service);
  }
  if (/prod\.(snappt|wintheknifefight)\.com/.test(service)) {
    return overrideUrls(envVars.production, service);
  }

  return envVars.production;
};

export default configVars();
