import styled from 'styled-components/macro';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
  padding-top: 1rem;
  overflow-y: auto;
`;
