import { ITextKeys as ITextLangKeys } from 'src/context/Language/types';
import { DupApplicationType } from '../../../types/api';
import getDefaultInfo from './getDefaultTextLang';

export interface StepInformation {
  title: string;
  description: string;
}

export interface Information {
  title: string;
  title_form: string;
  check_previous_text: string;
  instruction_upload_item_one: string;
  instruction_upload_item_two: string;
  instruction_upload_item_three: string;
  tool_tip_message: string;
  email_placeholder: string;
  url_terms_of_service: string;
  url_privacy_policy: string;
  dup_wizard: {
    title: string;
    step_1: StepInformation;
    step_2: StepInformation;
    step_3: StepInformation;
    step_4: StepInformation;
  };
}

/**
 * @param {DupApplicationType} type - DupApplicationType - this is the type of user that is using the form.
 * @param [translate] - (key: ITextLangKeys) => string
 * @returns an object with the following properties: name, title, title_form, check_previous_text,
 * instruction_upload_item_one, instruction_upload_item_two, tool_tip_message, email_placeholder,
 * url_terms_of_service, and url_privacy_policy
 */
const getInformation = (
  type: DupApplicationType,
  translate?: (key: ITextLangKeys) => string
): Information => {
  const t = !translate ? getDefaultInfo : translate;

  const INFO = {
    unauthenticated_user: {
      title: t('dup_info_applicant_title'),
      title_form: t('dup_info_applicant_title_form'),
      check_previous_text: t('dup_info_applicant_check_previous_text'),
      instruction_upload_item_one: t('dup_info_applicant_instruction_upload_item_one'),
      instruction_upload_item_two: t('dup_info_applicant_instruction_upload_item_two'),
      instruction_upload_item_three: t('dup_info_applicant_instruction_upload_item_three'),
      tool_tip_message: t('dup_info_applicant_tool_tip_message'),
      email_placeholder: t('dup_info_applicant_email_placeholder'),
      url_terms_of_service: t('dup_info_applicant_url_terms_of_service'),
      url_privacy_policy: t('dup_info_applicant_url_privacy_policy'),
      dup_wizard: {
        title: t('dup_info_wizard_title'),
        step_1: {
          title: t('dup_wizard_step_1_title'),
          description: t('dup_wizard_step_1_description')
        },
        step_2: {
          title: t('dup_wizard_step_2_title'),
          description: t('dup_wizard_step_2_description')
        },
        step_3: {
          title: t('dup_wizard_step_3_title'),
          description: t('dup_wizard_step_3_title')
        },
        step_4: {
          title: t('dup_wizard_step_4_title'),
          description: t('dup_wizard_step_4_title')
        }
      }
    },
    leasing_team: {
      title: t('dup_info_leasing_team_title'),
      title_form: t('dup_info_leasing_team_title_form'),
      check_previous_text: t('dup_info_leasing_team_check_previous_text'),
      instruction_upload_item_one: t('dup_info_leasing_team_instruction_upload_item_one'),
      instruction_upload_item_two: t('dup_info_leasing_team_instruction_upload_item_two'),
      instruction_upload_item_three: t('dup_info_leasing_team_instruction_upload_item_three'),
      tool_tip_message: t('dup_info_leasing_team_tool_tip_message'),
      email_placeholder: t('dup_info_leasing_team_email_placeholder'),
      url_terms_of_service: t('dup_info_leasing_team_url_terms_of_service'),
      url_privacy_policy: t('dup_info_leasing_team_url_privacy_policy'),
      dup_wizard: {
        title: t('dup_info_wizard_title'),
        step_1: {
          title: t('dup_wizard_step_1_title'),
          description: t('dup_wizard_step_1_title')
        },
        step_2: {
          title: t('dup_wizard_step_2_title'),
          description: t('dup_wizard_step_2_description')
        },
        step_3: {
          title: t('dup_wizard_step_3_title'),
          description: t('dup_wizard_step_3_title')
        },
        step_4: {
          title: t('dup_wizard_step_4_title'),
          description: t('dup_wizard_step_4_title')
        }
      }
    },
    AOA: {
      title: t('dup_info_aoa_title'),
      title_form: t('dup_info_aoa_title_form'),
      check_previous_text: t('dup_info_aoa_check_previous_text'),
      instruction_upload_item_one: t('dup_info_aoa_instruction_upload_item_one'),
      instruction_upload_item_two: t('dup_info_aoa_instruction_upload_item_two'),
      instruction_upload_item_three: t('dup_info_aoa_instruction_upload_item_three'),
      tool_tip_message: t('dup_info_aoa_tool_tip_message'),
      email_placeholder: t('dup_info_aoa_email_placeholder'),
      url_terms_of_service: t('dup_info_aoa_url_terms_of_service'),
      url_privacy_policy: t('dup_info_aoa_url_privacy_policy'),
      dup_wizard: {
        title: t('dup_info_wizard_title'),
        step_1: {
          title: t('dup_wizard_step_1_title'),
          description: t('dup_wizard_step_1_title')
        },
        step_2: {
          title: t('dup_wizard_step_2_title'),
          description: t('dup_wizard_step_2_description')
        },
        step_3: {
          title: t('dup_wizard_step_3_title'),
          description: t('dup_wizard_step_3_title')
        },
        step_4: {
          title: t('dup_wizard_step_4_title'),
          description: t('dup_wizard_step_4_title')
        }
      }
    }
  };
  return INFO[type];
};

export default getInformation;
