import styled from 'styled-components/macro';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
  padding-top: 1rem;
  overflow-y: auto;
`;

export const ReviewContainer = styled.div`
  width: 100%;
  max-width: 47rem;
  padding: 1rem;
  overflow-y: auto;
  border: 1px solid #012c57;
  border-radius: 24px;
  text-align: center;
`;

export const ReviewInfo = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SubmissionSubtitle = styled.div`
  width: 200px;
  height: 22px;
  background: #4d4e4e;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  @media ${({ theme }) => theme.device.mobile} {
    width: 175px;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }
`;

export const Gap = styled.div`
  height: 1rem;
`;

export const Row = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Terms = styled.span`
  margin-left: 0.25rem;
`;
