import { ChangeEvent, useCallback } from 'react';
// import { IconColor } from 'src/components/atoms/Icon';

import { FILES_TO_UPLOAD } from 'src/features/DUP/proofs/constants';
import { StyledTrigger, StyledUploadIcon, UploadContainer, UploadElement } from './styles';

export const WizardUploadButton = ({
  handleFileEvent,
  size = 'default',
  isLoading
}: {
  handleFileEvent: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  size?: 'default' | 'small';
  isLoading?: boolean;
}) => {
  const onButtonClick = useCallback(() => {
    const fileInput = document.getElementById('file');
    fileInput?.click();
  }, []);

  return (
    <UploadContainer>
      {/* <StyledIcon icon="help" color={IconColor.black} /> */}
      <UploadElement>
        <input
          type="file"
          name="file"
          id="file"
          data-testid="upload_button"
          className="inputFile"
          accept={FILES_TO_UPLOAD.ALLOW_FORMAT}
          onChange={async (e) => await handleFileEvent(e)}
          multiple
          disabled={isLoading}
        />
        <StyledTrigger onClick={onButtonClick}>
          <StyledUploadIcon icon="upload_button" size={size} disabled={isLoading} />
        </StyledTrigger>
      </UploadElement>
    </UploadContainer>
  );
};
