import Text from 'src/components/atoms/Text';
import useLanguage from 'src/context/Language/useLanguage';
import getDocumentTypes from 'src/features/DUP/helpers/getDocumentTypes';
import { Proof, SessionApplication, UnauthenticateSessionProofType } from 'src/types/api';

export function isIncomeDocumentProofType(proof: Proof): proof is Proof {
  return [
    UnauthenticateSessionProofType.SocialSecurityStatement,
    UnauthenticateSessionProofType.CashAppStatement,
    UnauthenticateSessionProofType.Paystub,
    UnauthenticateSessionProofType.SocialSecurityBenefitsLetter,
    UnauthenticateSessionProofType.DepartmentOfVeteransAffairsBenefitLetter
  ].includes(proof.type);
}

export function isAdditionalDocumentProofType(proof: Proof): proof is Proof {
  return [
    UnauthenticateSessionProofType.BankStatement,
    UnauthenticateSessionProofType.CreditDebitCardStatement,
    UnauthenticateSessionProofType.UtilityBill,
    UnauthenticateSessionProofType.TaxTranscript,
    UnauthenticateSessionProofType.InvestmentAccount
  ].includes(proof.type);
}

/**
 * Helper component for displaying the personal information of the applicant.
 */
export const PersonalInfo = (props: { application: SessionApplication }) => {
  const { application } = props;
  const { translate: t } = useLanguage();

  return (
    <>
      {application?.unit ? (
        <Text>
          {t('dup_wizard_unit')} {application.unit}
        </Text>
      ) : (
        ''
      )}
      <Text>
        {application.firstName} {application.lastName}
      </Text>
      <Text>{application.phone}</Text>
      <Text>{application.email}</Text>
    </>
  );
};

/**
 * Helper component for displaying the list of proofs.
 */
export const DocumentList = (props: { proofs: Proof[] }) => {
  const { proofs } = props;
  const { translate: t } = useLanguage();
  return (
    <div>
      {proofs.map((proof, i) => {
        const label = getDocumentTypes(t).find((type) => type.value === proof.type)?.label;
        return (
          <Text key={proof.id}>
            {label} {proofs.length > 1 ? `#${i + 1}` : ''}
          </Text>
        );
      })}
    </div>
  );
};

export { default as WizardSubmit } from './WizardSubmit';
