import { ThemeAttributes } from 'src/types/styled-components';
import styled from 'styled-components';
import { SpinnerColor, SpinnerProps, SpinnerSize } from './Spinner';

export const Container = styled.span<{ centered: boolean }>`
  margin-left: ${({ centered }) => (centered ? 0 : 1.125)}rem;
  ${({ centered }) =>
    centered
      ? `
    display: flex; 
    justify-content: center; 
    align-items: center;`
      : ''}
`;

const getSize = ({ size }: { size: keyof typeof SpinnerSize }) => {
  const baseSize = 0.9375;

  switch (size) {
    case SpinnerSize.small:
      return `${baseSize * 1}rem`;
    case SpinnerSize.normal:
      return `${baseSize * 1.25}rem`;
    case SpinnerSize.medium:
      return `${baseSize * 2}rem`;
    case SpinnerSize.big:
      return `${baseSize * 3}rem`;
    default:
      return `${baseSize * 1}rem`;
  }
};

const getColor = (theme: ThemeAttributes, color: keyof typeof SpinnerColor) => {
  switch (color) {
    case SpinnerColor.primary:
      return theme.color.blue[700];
    case SpinnerColor.secondary:
      return theme.color.purple[700];
    case SpinnerColor.cancel:
      return theme.color.red[700];
    case SpinnerColor.white:
      return theme.color.white;
    default:
      return theme.color.gray[600];
  }
};

export const SpinnerIcon = styled.div<SpinnerProps>`
  svg {
    width: ${getSize};
    height: ${getSize};
    path {
      fill: ${({ theme }) => theme.color.gray[600]};
      fill: ${({ theme, color }) => getColor(theme, color)};
    }
  }
`;
