import Text from 'src/components/atoms/Text';
import styled from 'styled-components';

export const WizardDescription = styled(Text)`
  font-size: 1rem;
  text-align: center;
  color: #012c57;
  font-family: 'PT Serif';
  font-weight: 400;
  line-height: 20px;
`;
