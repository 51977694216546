import { ITextKeys as ITextLangKeys } from 'src/context/Language/types';
import configVars from 'src/helpers/configVars';
import { FILES_TO_UPLOAD } from '../proofs/constants';

export const URL_FILE_P2PDF = `${configVars.frontend_url}/assets/documents/Print_To_Pdf_Instructions.pdf`;

export const PROOF_ERROR_KEYS = {
  UnsupportedFileType: 'File type not supported',
  PrintToPDFError: 'PrintToPDFError',
  PdfContainsTextError: 'PdfContainsTextError',
  FileNameLength: 'FileNameLength',
  FileNameRegx: 'InvalidFileName',
  WordDocument: 'Microsoft Word Document',
  InvalidPDF: 'InvalidPDF',
  UnknownError: 'UnknownError',
  EncryptedPdfError: 'EncryptedPdfError',
  PageLimitError: 'PageLimitError',
  ScannedDocuments: 'ScannedDocuments',
  InternalServerError: 'InternalServerError',
  MaxAttempts: 'MaxAttempts',
  PDFOver25MBError: 'PDFOver25MBError'
};

/**
 * It takes a key of type PROOF_ERROR_KEYS and a translate function as arguments and returns a string
 * @param type - PROOF_ERROR_KEYS,
 * @param translate - (key: ITextLangKeys, ...args: string[]) => string
 * @returns The function getProofErrorMessage returns a text translated.
 */
const getProofErrorMessage = (
  type: keyof typeof PROOF_ERROR_KEYS,
  translate: (key: ITextLangKeys, ...args: string[]) => string
) => {
  const t = translate;

  const PROOF_ERROR_MESSAGES = {
    [PROOF_ERROR_KEYS.UnsupportedFileType]: t('dup_proof_error_message_UnsupportedFileType'),
    [PROOF_ERROR_KEYS.FileNameLength]: t(
      'dup_proof_error_message_FileNameLength',
      FILES_TO_UPLOAD.MAX_FILE_NAME_LENGTH.toString()
    ),
    [PROOF_ERROR_KEYS.FileNameRegx]: t('dup_proof_error_message_FileNameRegx'),
    [PROOF_ERROR_KEYS.PrintToPDFError]: t('dup_proof_error_message_PrintToPdf', URL_FILE_P2PDF),
    [PROOF_ERROR_KEYS.PdfContainsTextError]: t('dup_proof_error_message_PdfContainsTextError'),
    [PROOF_ERROR_KEYS.WordDocument]: t('dup_proof_error_message_WordDocument'),
    [PROOF_ERROR_KEYS.InvalidPDF]: t('dup_proof_error_message_InvalidPDF'),
    [PROOF_ERROR_KEYS.UnknownError]: t('dup_proof_error_message_UnknownError'),
    [PROOF_ERROR_KEYS.PageLimitError]: t('dup_proof_error_message_PageLimitExceeded'),
    [PROOF_ERROR_KEYS.EncryptedPdfError]: t('dup_proof_error_message_PasswordException'),
    [PROOF_ERROR_KEYS.ScannedDocuments]: t('dup_proof_error_message_ScannedDocuments'),
    [PROOF_ERROR_KEYS.InternalServerError]: t('dup_proof_error_message_InternalServerError'),
    [PROOF_ERROR_KEYS.MaxAttempts]: t('dup_proof_error_message_MaxAttempts'),
    [PROOF_ERROR_KEYS.PDFOver25MBError]: t('dup_proof_error_message_PDFOver25MBError')
  };

  return PROOF_ERROR_MESSAGES[type];
};

export default getProofErrorMessage;
