import { ChangeEvent, useCallback, useState } from 'react';
import { ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { ProofForBox } from 'src/components/DUP/molecules/BoxProof/BoxProof';
import useLanguage from 'src/context/Language/useLanguage';
import { FILES_TO_UPLOAD } from 'src/features/DUP/proofs/constants';

import {
  CenteredColumn,
  FileNameRow,
  RemoveIcon,
  ReplaceButton,
  StyledText,
  StyledTrigger,
  UploadListSection,
  UploadsList
} from './styles';

import { WizardUploadButton } from 'src/components/DUP/molecules/WizardUploadButton/WizardUploadButton';

export const WizardUploadsList = ({
  proofs,
  handleReplaceFileEvent,
  handleFileEvent,
  onDeleteProof,
  isLoading
}: {
  proofs: ProofForBox[];
  onDeleteProof: (id: string) => void;
  handleReplaceFileEvent: (e: ChangeEvent<HTMLInputElement>, proof: ProofForBox) => Promise<void>;
  handleFileEvent: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  isLoading?: boolean;
}) => {
  const { translate: t } = useLanguage();
  const [selectedProofIndex, setSelectedProofIndex] = useState(0);

  const onReplaceButtonClick = useCallback(() => {
    const fileInput = document.getElementById('file_reupload');
    fileInput?.click();
  }, []);

  const onRemoveFileClick = useCallback(
    (docId: string) => {
      if (!isLoading) {
        onDeleteProof(docId);
      }
    },
    [isLoading, onDeleteProof]
  );

  return (
    <UploadListSection>
      <CenteredColumn gap={0.5}>
        <Icon icon="thumbnail-placeholder" />
        <input
          type="file"
          name={'file_reupload'}
          id={'file_reupload'}
          className="inputFile"
          accept={FILES_TO_UPLOAD.ALLOW_FORMAT}
          disabled={isLoading}
          onChange={(e) => handleReplaceFileEvent(e, proofs[selectedProofIndex])}
        />

        <ReplaceButton
          name="replace_proof"
          isDisabled={isLoading}
          onClick={onReplaceButtonClick}
          variant={ButtonVariant.contained}
        >
          {t('dup_wizard_replace_button_label')}
        </ReplaceButton>
      </CenteredColumn>
      <CenteredColumn gap={1.5}>
        <UploadsList>
          <StyledText bold>{t('dup_wizard_uploaded_documents')}</StyledText>
          {proofs.map((doc, index) => {
            const active = selectedProofIndex === index;
            return (
              <FileNameRow key={doc.id}>
                <StyledTrigger onClick={() => setSelectedProofIndex(index)}>
                  <StyledText active={active}>{`Paystub${index + 1}.pdf`}</StyledText>
                </StyledTrigger>

                <StyledTrigger onClick={() => onRemoveFileClick(doc.id)}>
                  <RemoveIcon active={active} icon="bordered-close-icon" />
                </StyledTrigger>
              </FileNameRow>
            );
          })}
        </UploadsList>

        <WizardUploadButton handleFileEvent={handleFileEvent} size="small" isLoading={isLoading} />
      </CenteredColumn>
    </UploadListSection>
  );
};
