import { Icon } from 'src/components/atoms/Icon';
import styled from 'styled-components';

export const UploadContainer = styled.div`
  position: relative;
  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 1.5rem;
  }
`;

export const StyledUploadIcon = styled(Icon)<{ size: 'default' | 'small'; disabled?: boolean }>`
  & svg {
    width: ${({ size }) => (size === 'small' ? '116px' : 'unset')};
    height: ${({ size }) => (size === 'small' ? '109px' : 'unset')};
    fill: ${({ disabled }) => (disabled ? 'grey' : undefined)};
  }
`;

export const UploadElement = styled.div`
  .inputFile {
    display: none;
  }
`;

export const StyledTrigger = styled.span`
  cursor: pointer;
`;
