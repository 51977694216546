import Text, { TextAlign, TextVariant } from 'src/components/atoms/Text';
import {
  DocumentList,
  isAdditionalDocumentProofType,
  isIncomeDocumentProofType,
  PersonalInfo
} from 'src/components/DUP/molecules/WizardSubmit';
import useLanguage from 'src/context/Language/useLanguage';
import { useResource } from 'src/hooks/useResource';
import { Proof, SessionApplication } from 'src/types/api';
import { Col, ReviewContainer, ReviewInfo, SubmissionSubtitle } from './styles';

interface ApplicationInfoProps {
  application: SessionApplication;
}

/**
 * This component shows the application information for the user.
 */
const ApplicationInfo = (props: ApplicationInfoProps) => {
  const { application } = props;
  const { translate: t } = useLanguage();

  const [proofs = []] = useResource<Proof[]>(`/session/documents`);

  const incomeDocuments = proofs.filter(isIncomeDocumentProofType);
  const additionalDocuments = proofs.filter(isAdditionalDocumentProofType);

  // Component wrapper for rendering the subtitle text for each column of information.
  const Subtitle = (props: { children: React.ReactNode }) => (
    <SubmissionSubtitle>
      <Text align={TextAlign.center} color="white" variant={TextVariant.small}>
        {props.children}
      </Text>
    </SubmissionSubtitle>
  );
  return (
    <ReviewContainer>
      <h2>{t('dup_wizard_submission_confirmation')}</h2>
      <ReviewInfo>
        <Col>
          <Subtitle>{t('dup_wizard_step_1_title')}</Subtitle>
          <PersonalInfo application={application} />
        </Col>
        <Col>
          <Subtitle>{t('dup_wizard_income_documents')}</Subtitle>
          <DocumentList proofs={incomeDocuments} />
        </Col>
        <Col>
          <Subtitle>{t('dup_wizard_step_3_title')}</Subtitle>
          <DocumentList proofs={additionalDocuments} />
        </Col>
      </ReviewInfo>
    </ReviewContainer>
  );
};

export default ApplicationInfo;
