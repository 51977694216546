import Button from 'src/components/atoms/Button';
import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components';

export const CenteredColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}rem` : 'unset')};
`;

export const Container = styled(CenteredColumn)`
  flex: 1;
  width: 100%;
  padding-top: 1rem;
`;

export const StyledRow = styled(Row)`
  margin: auto;
  gap: 2.5rem;
  @media ${({ theme }) => theme.device.mobile} {
    gap: 2rem;
  }
`;

export const StyledButton = styled(Button)`
  background: #32b2fc;
  border-radius: 10px;
  border: 1px solid #32b2fc;
  font-family: 'Montserrat';
  line-height: 20px;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-top: 2rem;
  padding: 1rem 2.5rem;
  font-size: 16px;
  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 1rem;
  }
`;
