import { pick, snakeCase } from 'lodash';
import QueryString from 'qs';
import React, { CSSProperties, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Checkbox from 'src/components/atoms/Checkbox/Checkbox';
import InputText, { InputError, InputTextType } from 'src/components/atoms/InputText/InputText';
import Row from 'src/components/atoms/Row/Row';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { ITextKeys } from 'src/context/Language/types';
import useLanguage from 'src/context/Language/useLanguage';
import { Information } from 'src/features/DUP/helpers/getInformation';
import { useDebouncedCall } from 'src/hooks/useDebouncedCall';
import {
  DupApplicationType,
  SessionApplication,
  SessionApplicationUpsertBody,
  SessionProperty
} from 'src/types/api';

export type FormApplicantProps = {
  type: DupApplicationType;
  information: Information;
  application?: SessionApplication;
  property?: Partial<SessionProperty>;
  setIsFormValid: React.Dispatch<SetStateAction<boolean>>;
  inputProps?: { alwaysShowLabel: boolean; style: CSSProperties };
  onUpdateApplication: (
    updates: Partial<SessionApplication>
  ) => Promise<SessionApplication | { error: string }>;
};

const FormKeys: (keyof SessionApplication)[] = [
  'firstName',
  'middleInitial',
  'lastName',
  'phone',
  'email',
  'notificationEmail',
  'unit'
];

export const useFormApplicant = (props: FormApplicantProps) => {
  const { search } = useLocation();
  const { translate: t } = useLanguage();
  const {
    type,
    information,
    property,
    application,
    setIsFormValid,
    onUpdateApplication,
    inputProps
  } = props;
  const [inputErrors, setInputErrors] = React.useState<InputError[]>([]);

  const form = useForm<SessionApplicationUpsertBody>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...pick(application, FormKeys),
      ...initialValuesFromQueryString(search)
    }
  });

  React.useEffect(() => {
    const requiredFormEntries = Object.entries(form.getValues()).filter((entry) => {
      if (entry[0] === 'middleInitial') {
        return false;
      } else if (entry[0] === 'unit' && !property?.unitIsRequired) {
        return false;
      } else if (entry[0] === 'notificationEmail' && type !== DupApplicationType.LEASING_TEAM) {
        return false;
      }
      return true;
    });

    setIsFormValid(!inputErrors.length && requiredFormEntries.every((entry) => !!entry[1]));
  }, [application, inputErrors.length, form, property?.unitIsRequired, setIsFormValid, type]);

  const onSave = useDebouncedCall<void>(async () => {
    const application = form.getValues();
    try {
      await onUpdateApplication({ ...application });
      setInputErrors([]);
    } catch (error) {
      setInputErrors(inputErrorsFromResponse(error as { message: string }));
    }
  });

  const onChanging = async (changes: { field: string; value: string | boolean }) => {
    const { field, value } = changes;
    form.setValue(field as keyof SessionApplicationUpsertBody, value ?? '');
    await onSave();
  };

  const PropertyNameInput = (
    <InputText
      placeholder={t('dup_form_label_property_name')}
      name="propertyName"
      type={InputTextType.text}
      isReadonly
      defaultValue={property?.name}
      errors={[]}
    />
  );

  const UnitInput = property?.unitIsRequired && (
    <InputText
      {...inputProps}
      placeholder={t('dup_form_label_unit_num')}
      name="unit"
      type={InputTextType.text}
      required={property?.unitIsRequired}
      errors={inputErrors}
      onChange={({ value }) => {
        onChanging({
          field: 'unit',
          value
        });
      }}
    />
  );

  const FirstNameInput = (
    <InputText
      {...inputProps}
      placeholder={t('dup_form_label_first_name')}
      name="firstName"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'firstName',
          value
        })
      }
      required={true}
    />
  );

  const MiddleInitialInput = (
    <InputText
      {...inputProps}
      placeholder={t('dup_form_label_middle_initial')}
      name="middleInitial"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) => {
        onChanging({
          field: 'middleInitial',
          value
        });
      }}
    />
  );

  const LastNameInput = (
    <InputText
      {...inputProps}
      placeholder={t('dup_form_label_last_name')}
      name="lastName"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'lastName',
          value
        })
      }
      required={true}
    />
  );

  const EmailInput = (
    <InputText
      {...inputProps}
      placeholder={information.email_placeholder}
      name="email"
      type={InputTextType.email}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'email',
          value
        })
      }
      required={true}
    />
  );

  const PhoneInput = property?.phoneIsRequired && type !== DupApplicationType.AOA && (
    <InputText
      {...inputProps}
      placeholder={t('dup_form_label_phone')}
      name="phone"
      type={InputTextType.tel}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'phone',
          value
        })
      }
      required={true}
    />
  );

  const NotificationEmailInput = type === DupApplicationType.LEASING_TEAM && (
    <ToolTip content={information.tool_tip_message} direction={ToolTipDirection.bottom_fit}>
      <InputText
        placeholder={t('dup_form_label_email_notifications')}
        name="notificationEmail"
        type={InputTextType.email}
        errors={inputErrors}
        onChange={({ value }) =>
          onChanging({
            field: 'notificationEmail',
            value
          })
        }
        required={true}
      />
    </ToolTip>
  );

  const HavePrevSubmitRow = property?.name && (
    <Row key={`key_HavePreviousSubmit_${!!application?.hasPreviouslySubmitted}`}>
      <Checkbox
        name="HavePreviousSubmit"
        value="havePreviousSubmit"
        showLabel={true}
        label={t('dup_info_applicant_check_previous_text').replace(
          '{Property_name}',
          property?.name
        )}
        isChecked={!!application?.hasPreviouslySubmitted}
        onClick={(e) =>
          onChanging({
            field: 'hasPreviouslySubmitted',
            value: e.isChecked
          })
        }
      />
    </Row>
  );

  return {
    form,
    PropertyNameInput,
    UnitInput,
    FirstNameInput,
    MiddleInitialInput,
    LastNameInput,
    EmailInput,
    PhoneInput,
    NotificationEmailInput,
    HavePrevSubmitRow
  };
};

function initialValuesFromQueryString(search: string) {
  const query = QueryString.parse(search.replace(/^\?/, ''));
  const values: { [key: string]: string | undefined } = {};
  for (const field of FormKeys) {
    for (const form of [
      field,
      snakeCase(field),
      `applicant.${field}`,
      `applicant.${snakeCase(field)}`
    ]) {
      if (query[form]) {
        values[field] = `${query[form]}`;
      }
    }
  }
  return values;
}

function inputErrorsFromResponse(error: { message: ITextKeys | string }) {
  const splitErrorMessages = error.message.split(',');
  return splitErrorMessages.map((errorMessage) => {
    const errorMessageParsed = errorMessage.split(':');
    return {
      field: errorMessageParsed[0].trim(),
      message: errorMessageParsed[1].trim() as ITextKeys | string,
      errorParam: errorMessageParsed[2]?.trim()
    };
  });
}
