import React, { ChangeEvent, useState } from 'react';
import { ButtonVariant } from 'src/components/atoms/Button';
import { ProofForBox } from 'src/components/DUP/molecules/BoxProof/BoxProof';
import useLanguage from 'src/context/Language/useLanguage';
import { makeRequest } from 'src/hooks/useResource';

import { Information } from 'src/features/DUP/helpers/getInformation';
import { FILES_TO_UPLOAD } from 'src/features/DUP/proofs/constants';
import { useResource } from 'src/hooks/useResource';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import {
  CreateProofRequest,
  JobErrorType,
  Proof,
  ProofResponse,
  UnauthenticateSessionProofType
} from 'src/types/api';
import { Container, StyledButton, StyledRow } from './styles';

import { WizardUploadButton } from 'src/components/DUP/molecules/WizardUploadButton/WizardUploadButton';
import { WizardUploadInstructions } from 'src/components/DUP/molecules/WizardUploadInstructions/WizardUploadInstructions';
import { WizardUploadsList } from 'src/components/DUP/molecules/WizardUploadsList/WizardUploadsList';

export const WizardDocumentUploadSection = (
  props: DupScreenStepProps & { information: Information }
) => {
  const { property, onFinished } = props;

  const { translate: t } = useLanguage();

  const [uploadingFiles, setUploadingFiles] = useState<ProofForBox[]>([]);
  const [documentTypeSelected] = useState<UnauthenticateSessionProofType>();
  const [proofIsProcessing, setProofIsProcessing] = useState(false);

  const [proofs = [], { refresh }] = useResource<Proof[]>(`/session/documents`);

  const handleUploadFiles = async (proofsToUpload: CreateProofRequest[]) => {
    if (proofsToUpload.length + proofs.length > FILES_TO_UPLOAD.MAX_FILES) {
      alert(t('dup_proof_max_files', FILES_TO_UPLOAD.MAX_FILES.toString()));
      return [];
    }

    setProofIsProcessing(true);

    const failedDocs: ProofForBox[] = [];
    for (const { upload, type } of proofsToUpload) {
      const formData = new FormData();
      formData.append('upload', upload);
      formData.append('type', type);
      const response = await makeRequest<
        ProofResponse | { error: string; failedChecks: JobErrorType[] }
      >(`/session/documents?checks=true`, 'POST', formData, true);

      if ('error' in response) {
        const overSizeError =
          response.error === 'File too large, only files under 25MB will be uploaded';

        failedDocs.push({
          id: upload.name,
          type,
          jobs_error: overSizeError ? ['PDFOver25MBError'] : response.failedChecks,
          thumb: ''
        });
      }
    }

    await refresh?.();
    setProofIsProcessing(false);

    return failedDocs;
  };

  const handleReplaceFileEvent = async (
    e: ChangeEvent<HTMLInputElement>,
    replaceProof: ProofForBox
  ) => {
    const chosenFile = Array.prototype.slice.call(e.target.files)[0];
    const replacingSavedProof = !replaceProof.jobs_error;
    const type = replaceProof.type;

    const placeholderFile: ProofForBox = {
      id: replaceProof.id,
      type,
      thumb: '',
      jobs_error: [],
      isReplacing: replacingSavedProof,
      isLoading: true
    };

    setUploadingFiles((prevState) => [
      ...prevState.filter((f) => f.id !== placeholderFile.id),
      placeholderFile
    ]);

    if (replacingSavedProof) {
      await makeRequest(`/session/documents/${replaceProof.id}`, 'DELETE');
    }

    const failedFiles = await handleUploadFiles([{ upload: chosenFile, type }]);

    setUploadingFiles((prevState) => [
      ...prevState.filter((f) => f.id !== placeholderFile.id),
      ...failedFiles
    ]);
  };

  const handleFileEvent = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const type = documentTypeSelected || UnauthenticateSessionProofType.Paystub;

    const placeholderFiles = chosenFiles.map(
      (upload: File): ProofForBox => ({
        id: upload.name,
        type: type,
        thumb: '',
        jobs_error: [],
        isReplacing: false,
        isLoading: true
      })
    );

    setUploadingFiles((prevState) => [...prevState, ...placeholderFiles]);

    const failedFiles = await handleUploadFiles(
      chosenFiles.map((upload) => ({ upload, type })) satisfies CreateProofRequest[]
    );

    setUploadingFiles((prevState) => [
      ...prevState.filter((p) => !placeholderFiles.includes(p)),
      ...failedFiles
    ]);
  };

  const onDeleteProof = async (proof_id: string) => {
    await makeRequest(`/session/documents/${proof_id}`, 'DELETE');
    await refresh?.();
    setUploadingFiles((prevState) => prevState.filter((f) => f.id !== proof_id));
  };

  // The proofs array are the fully uploaded documents. Mix in documents we're
  // currently uploading to create the displayed set. Some uploading documents
  // replace existing documents.
  const displayed: ProofForBox[] = [
    ...proofs.map((p) => uploadingFiles.find((u) => u.isReplacing && u.id === p.id) || p),
    ...uploadingFiles.filter((u) => !u.isReplacing)
  ];

  if (displayed.length) {
    return (
      <Container>
        <StyledRow alignItems="center">
          <WizardUploadsList
            proofs={displayed}
            onDeleteProof={onDeleteProof}
            handleReplaceFileEvent={handleReplaceFileEvent}
            handleFileEvent={handleFileEvent}
            isLoading={proofIsProcessing}
          />

          <WizardUploadInstructions documentsAmount={property?.paystub} />
        </StyledRow>
        <StyledButton
          name="next"
          isDisabled={proofIsProcessing}
          onClick={() => onFinished()}
          variant={ButtonVariant.contained}
        >
          {t('dup_button_label_next')}
        </StyledButton>
      </Container>
    );
  }

  return (
    <Container>
      <StyledRow alignItems="center">
        <WizardUploadInstructions documentsAmount={property?.paystub} />
        <WizardUploadButton handleFileEvent={handleFileEvent} />
      </StyledRow>
      <StyledButton
        name="submit"
        isDisabled={proofIsProcessing}
        onClick={() => onFinished()}
        variant={ButtonVariant.contained}
      >
        {t('dup_button_label_skip')}
      </StyledButton>
    </Container>
  );
};
